import { makeActionCreator } from '../../helper/Store';

export const GameTypes = {
    SELECT_RACING_STABLE: 'Game/SELECT_RACING_STABLE',
};

const selectRacingStable = makeActionCreator(
    GameTypes.SELECT_RACING_STABLE,
    {
        racingStable: null,
    },
);

export const GameActions = {
    selectRacingStable,
};