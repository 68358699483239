import React                    from 'react';
import styles                   from './styles.module.scss';
import PropTypes                from '../../PropTypes';
import GameInfoOverlay          from '../GameInfoOverlay';
import GameInfoOverlayAlignment from '../GameInfoOverlay/GameInfoOverlayAlignment';
import I18n                     from 'i18next';
import moment                   from 'moment';
import Cast                     from '../../../helper/Cast';
import ComponentHelper          from '../../../helper/ComponentHelper';
import deLocale                 from 'moment/locale/de';

class TimeCountdownGameInfoOverlay extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            currentTimeString: '0',
        };
    }

    componentDidMount () {
        setInterval(
            () => {
                const end        = moment.unix(this.props.endTime.seconds);
                const now        = moment();
                const difference = moment.duration(end.diff(now));

                this.setState({
                    currentTimeString: (
                                           difference.isValid ?
                                               difference.locale('de').humanize() :
                                               ''
                                       ),
                });
            },
            1000,
        );
    }

    render () {
        return (
            <GameInfoOverlay
                alignment={GameInfoOverlayAlignment.right}
                label={I18n.t('end')}
                value={this.state.currentTimeString}
            />
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = TimeCountdownGameInfoOverlay;

Component.propTypes = {
    endTime: PropTypes.number,
};

Component.defaultProps = {
    endTime: 0,
};

Component.renderAffectingProps = [];

Component.renderAffectingStates = ['currentTimeString'];

export default Component;