//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    au: 'flag_au.png',
    ca: 'flag_ca.png',
    ch: 'flag_ch.png',
    gb: 'flag_gb.png',
    it: 'flag_it.png',
    nz: 'flag_nz.png',
    us: 'flag_us.png',
};
