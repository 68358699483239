//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import I18n                     from 'i18next';
import GameProvider             from '../helper/GameProvider';
import PageTitleHelper          from '../helper/PageTitle';
import React                    from 'react';
import DebugOverlay             from '../components/stateless/DebugOverlay';
import Table                    from '../components/stateless/Table';
import TableWrapper             from '../components/stateless/TableWrapper';
import Spacer                   from '../components/stateless/Spacer';
import { Component }            from 'react';
import { Helmet }               from 'react-helmet';
import styles                   from '../styles.module.scss';
import TableTheme               from '../components/stateless/Table/TableTheme';
import CurrentTimeRaw           from '../components/stateless/CurrentTimeRaw';
import FootballGame             from '../components/stateless/FootballGame';
import F1RaceTrack              from '../components/stateless/F1RaceTrack';
import FirebaseEndpoints        from '../constants/FirebaseEndpoints';
import FirebaseDataProvider     from '../helper/FirebaseDataProvider';
import FirebaseDataTransformer  from '../helper/FirebaseDataTransformer';
import { FirebaseDatabaseNode } from '@react-firebase/database';
import hash                     from 'object-hash';
import { connect }              from 'react-redux';
import { bindActionCreators }   from 'redux';
import _                        from 'lodash';
import { DebugActions }         from '../store/actions/debug';
import TableColor               from '../components/stateless/Table/TableColor';
import TabStyle                 from '../components/stateless/TableWrapper/TabStyle';
import TabColor                 from '../components/stateless/TableWrapper/TabColor';
import RacingStableDropDown     from '../components/connected/RacingStableDropDown';
import classNames               from 'classnames';
import mobileHeader             from '../assets/images/bundesliga/background-mobile.jpg';

class Screen extends Component {
    constructor (props) {
        super(props);

        this.state = {
            constructorData:              [],
            constructorDataRaw:           [],
            driverData:                   [],
            driverDataRaw:                [],
            flashTableData:               [],
            flashTableDataRaw:            [],
            parallelGamesData:            [],
            parallelGamesDataRaw:         [],
            racesData:                    [],
            racesDataRaw:                 [],
            racingStableData:             [],
            racingStableDataRaw:          [],
            specialJerseysData:           [],
            specialJerseysDataRaw:        [],
            mannschaftenBereicheData:     [],
            mannschaftenBereicheDataRaw:  [],
            mannschaftenBereicheData2:    [],
            mannschaftenBereicheData2Raw: [],
            tickerData:                   [],
            tickerDataRaw:                [],
            spielerData:                  [],
            spielerDataRaw:               [],
            timeData:                     [],
            timeDataRaw:                  [],
            ts7Data:                      [],
            ts7DataRaw:                   [],
            statisticsData:               [],
            statisticsDataRaw:            [],
        };
    }

    componentDidMount () {
        FirebaseDataProvider.getConstructorData(this.constructorTableDataChanged);
        FirebaseDataProvider.getDriverData(this.driverTableDataChanged);
        FirebaseDataProvider.getFlashTableData(this.flashTableDataChanged);
        FirebaseDataProvider.getRacesTableData(this.racesTableDataChanged);
        FirebaseDataProvider.getRacingTimeData(this.racesTimeDataChanged);
        FirebaseDataProvider.getStatisticsData(this.statisticsDataChanged);
        FirebaseDataProvider.getRacingStableTableData(this.racingStableTableDataChanged);
        FirebaseDataProvider.getSpecialJerseysData(this.specialJerseysDataChanged);
        FirebaseDataProvider.getTickerData(this.tickerDataChanged); // benutzt
        FirebaseDataProvider.getMannschaftenBereicheData(this.mannschaftenBereicheChanged); // benutzt
        FirebaseDataProvider.getSpielerData(this.spielerChanged); // benutzt
        FirebaseDataProvider.getTS7TableData(this.ts7DataChanged);
    }

    render () {
        return (
            <div>
                <Helmet>
                    <title>
                        {PageTitleHelper.getPageTitle(I18n.t('home'))}
                    </title>
                </Helmet>
                <Spacer height={40} />
                <div className={styles.homeContent}>
                    <div className={styles.firstRow}>
                        <div
                            className={classNames(
                                styles.text,
                                styles.textLeft,
                            )}
                        >
                            {_.get(this.state, 'statisticsDataRaw[0].data.gesamtziel')}
                            /
                            {_.get(this.state, 'statisticsDataRaw[0].data.termine')}
                        </div>
                        <RacingStableDropDown
                            racesData={this.state.mannschaftenBereicheDataRaw}
                        />
                        <div
                            className={classNames(
                                styles.text,
                                styles.textRight,
                            )}
                        >
                            <CurrentTimeRaw />
                        </div>
                    </div>
                    {this.renderFlashTable()}
                    <div className={styles.homeTabTable}>
                        <TableWrapper
                            renderCallback={this.renderActiveTab}
                            key={Math.random()}
                            tabColor={TabColor.red}
                            tabStyle={TabStyle.diagonal}
                            tabTitles={[
                                I18n.t('ticker'),
                                I18n.t('racingStable'),
                                I18n.t('player'),
                                I18n.t('ts7'),
                            ]}
                            tabWidth={160}
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderActiveTab = (activeTabIndex) => {
        switch (activeTabIndex) {
            // @formatter:off
             case 0: return this.renderTickerTableData();
             case 1: return this.renderRacingStableTable();
             case 2: return this.renderConstructorTable();
             case 3: return this.renderTS7Table();
             // @formatter:on
        }

        return null;
    };

    renderCurrentRace = () => {
        return false;
    };

    renderRaceTrack = () => {
        const endTimeData = _.find(this.state.timeData, { id: GameProvider.getGameId() });
        const endTime     = _.get(endTimeData, 'data.ende');

        return (
            <F1RaceTrack
                endTime={endTime}
                goal={_.get(this.state, 'statisticsDataRaw[0].data.gesamtziel')}
                goalProgress={_.get(this.state, 'statisticsDataRaw[0].data.termine_gesamt')}
                racesData={this.state.racesDataRaw}
                specialJerseysData={this.state.specialJerseysDataRaw}
                timeData={this.state.timeDataRaw}
            />
        );
    };

    renderCurrentRaceTable = () => {
        return (
            <div className={styles.homeCurrentRace}>
                <TableWrapper
                    renderCallback={this.renderCurrentRaceTableData}
                    tabColor={TabColor.red}
                    tabStyle={TabStyle.diagonal}
                    tabTitles={[
                        I18n.t('currentRace'), // TODO: muss hier die spielnummer stehen?
                    ]}
                    tabWidth={190}
                />
            </div>
        );
    };

    renderCurrentRaceTableData = () => {
        return (
            <Table
                color={TableColor.blackTranslucent}
                columns={[
                    {
                        title:      I18n.t('rankShort'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('constructor'),
                        fontWeight: 500,
                    },
                    {
                        title:         I18n.t('racingStable'),
                        fontWeight:    500,
                        maxTextLength: 16,
                    },
                    {
                        title:      I18n.t('meters'),
                        fontWeight: 500,
                    },
                    //{
                    //    title:      I18n.t('rounds'),
                    //    fontWeight: 800,
                    //},
                    {
                        title:      I18n.t('score'),
                        fontWeight: 500,
                    },
                ]}
                key={Math.random()}
                data={this.state.racesData}
                fullWidthColumnIndex={5}
                hasPaddingTop={true}
                theme={TableTheme.small}
            />
        );
    };

    renderRacingStableTable = () => {
        const sortedData = _.sortBy(this.state.mannschaftenBereicheData, 'cellData[5].text').reverse();

        return (
            <Table
                color={TableColor.blackTranslucent}
                columns={[
                    {
                        title:      I18n.t('team'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t1Short'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t2Short'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t3TnShort'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('service'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('sum'),
                        fontWeight: 500,
                    },
                ]}
                key={Math.random()}
                data={sortedData}
                fullWidthColumnIndex={0}
                hasPaddingTop={true}
                theme={TableTheme.small}
            />
        );
    };

    renderConstructorTable = () => {
        let data = this.state.spielerData;

        if (this.props.racingStable && this.props.racingStable !== 'ALL') {
            const newData = _.filter(data, { racingStable: this.props.racingStable });

            if (newData.length > 0) {
                data = newData;
            }
        }

        return (
            <Table
                allowHighlighting={false}
                color={TableColor.blackTranslucent}
                columns={[
                    {
                        title:      I18n.t('player'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('team'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t1Short'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t2Short'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t3TnShort'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('service'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('sum'),
                        fontWeight: 500,
                    },
                ]}
                key={Math.random()}
                data={data}
                fullWidthColumnIndex={0}
                hasPaddingTop={true}
                theme={TableTheme.small}
            />
        );
    };

    renderDriverTable = () => {
        let data = this.state.driverData;

        if (this.props.racingStable && this.props.racingStable !== 'ALL') {
            const newData = _.filter(data, { racingStable: this.props.racingStable });

            if (newData.length > 0) {
                data = newData;
            }
        }

        return (
            <Table
                allowHighlighting={false}
                color={TableColor.blackTranslucent}
                columns={[
                    {
                        title:      I18n.t('constructor'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('driver'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('racingStable'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t1Short'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t2Short'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('t3TnShort'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('service'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('sum'),
                        fontWeight: 500,
                    },
                ]}
                key={Math.random()}
                data={data}
                fullWidthColumnIndex={7}
                hasPaddingTop={true}
                theme={TableTheme.small}
            />
        );
    };

    renderTS7Table = () => {
        let data = this.state.ts7Data;

        return (
            <Table
                color={TableColor.blackTranslucent}
                columns={[
                    {
                        title:      I18n.t('vpShort'),
                        fontWeight: 500,
                    },
                ]}
                key={Math.random()}
                data={data}
                fullWidthColumnIndex={0}
                hasPaddingTop={true}
                theme={TableTheme.small}
            />
        );
    };

    renderTickerTableData = () => {
        return (
            <Table
                color={TableColor.blackTranslucent}
                columns={[
                    {
                        title:      I18n.t('player'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('appointmentType'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('team'),
                        fontWeight: 500,
                    },
                ]}
                key={Math.random()}
                data={this.state.tickerData}
                fullWidthColumnIndex={0}
                hasPaddingTop={true}
                theme={TableTheme.small}
            />
        );
    };

    renderFlashTable = () => {
        return (
            <div
                className={styles.homeFlashTable}
                key={Math.random()}
            >
                <TableWrapper
                    renderCallback={this.renderFlashTableData}
                    tabColor={TabColor.red}
                    tabStyle={TabStyle.diagonal}
                    tabTitles={[
                        I18n.t('flashTable'),
                    ]}
                    tabWidth={180}
                />
            </div>
        );
    };

    renderFlashTableData = () => {
        console.log('this.state.mannschaftenBereicheData2', this.state.mannschaftenBereicheData2);

        return (
            <Table
                color={TableColor.blackTranslucent}
                columns={[
                    {
                        title:      I18n.t('rank'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('scoring'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('team'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('team2'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('appointments'),
                        fontWeight: 500,
                    },
                    {
                        title:      I18n.t('tvp'),
                        fontWeight: 500,
                    },
                ]}
                key={Math.random()}
                data={this.state.mannschaftenBereicheData2}
                fullWidthColumnIndex={3}
                hasPaddingTop={true}
                theme={TableTheme.small}
            />
        );
    };

    constructorTableDataChanged = (data, initial) => {
        console.log('constructorTableDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.constructorDataRaw,
            data,
            initial,
        );

        newData = _.sortBy(
            newData,
            'data.score',
        ).reverse();

        this.setState({
            constructorData:    FirebaseDataTransformer.transformConstructorTableData(newData),
            constructorDataRaw: newData,
        });
    };

    mergeChangeSet = (initialData, changeSetOrData, initial) => {
        let newData = [...initialData];

        if (initial) {
            newData = [...changeSetOrData];
        } else {
            for (const change of changeSetOrData) {
                switch (change.type) {
                    case 'added':
                        newData.unshift(change.document);

                        break;

                    case 'modified':
                        const targetIndexToEdit = _.findIndex(newData, { id: change.document.id });

                        newData[targetIndexToEdit] = change.document;

                        break;

                    case 'removed':
                        const targetIndexToRemove = _.findIndex(newData, { id: change.document.id });

                        newData.splice(targetIndexToRemove, 1);

                        break;
                }
            }
        }

        return newData;
    };

    driverTableDataChanged = (data, initial) => {
        console.log('driverTableDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.driverDataRaw,
            data,
            initial,
        );

        newData = _.sortBy(
            newData,
            'data.Gesamt',
        ).reverse();

        this.setState({
            driverData:    FirebaseDataTransformer.transformDriverTableData(newData),
            driverDataRaw: newData,
        });
    };

    flashTableDataChanged = (data, initial) => {
        console.log('flashTableDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.flashTableDataRaw,
            data,
            initial,
        );

        newData = _.sortBy(
            newData,
            'data.rang',
        );

        this.setState({
            flashTableData:    FirebaseDataTransformer.transformFlashTableData(newData),
            flashTableDataRaw: newData,
        });
    };

    parallelGamesDataChanged = (data, initial) => {
        console.log('parallelGamesDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.parallelGamesDataRaw,
            data,
            initial,
        );

        this.setState({
            parallelGamesData:    FirebaseDataTransformer.transformTeamData(newData),
            parallelGamesDataRaw: newData,
        });
    };

    racesTableDataChanged = (data, initial) => {
        console.log('racesTableDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.racesDataRaw,
            data,
            initial,
        );

        newData = _.sortBy(
            newData,
            'data.rang',
        );

        this.setState({
            racesData:    FirebaseDataTransformer.transformRacesTableData(newData),
            racesDataRaw: newData,
        });
    };

    racesTimeDataChanged = (data, initial) => {
        console.log('racesTimeDataChanged', data);

        this.setState({
            timeData:    data,
            timeDataRaw: data,
        });
    };

    statisticsDataChanged = (data, initial) => {
        console.log('statisticsDataChanged', data);

        this.setState({
            statisticsData:    data,
            statisticsDataRaw: data,
        });
    };

    racingStableTableDataChanged = (data, initial) => {
        console.log('racingStableTableDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.racingStableDataRaw,
            data,
            initial,
        );

        newData = _.sortBy(
            newData,
            'data.Gesamt',
        ).reverse();

        this.setState({
            racingStableData:    FirebaseDataTransformer.transformRacingStableData(newData),
            racingStableDataRaw: newData,
        });
    };

    squadsDataChanged = (data) => {
        console.log('squadsDataChanged', data);

        this.setState({
            squadsData: FirebaseDataTransformer.transformTeamData(data),
        });
    };

    teamDataChanged = (data) => {
        console.log('teamDataChanged', data);

        this.setState({
            teamData: FirebaseDataTransformer.transformTeamData(data),
        });
    };

    specialJerseysDataChanged = (data, initial) => {
        console.log('specialJerseysDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.specialJerseysDataRaw,
            data,
            initial,
        );

        this.setState({
            specialJerseysData:    newData,
            specialJerseysDataRaw: newData,
        });
    };

    tickerDataChanged = (data, initial) => {
        console.log('tickerDataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.tickerDataRaw,
            data,
            initial,
        );

        this.setState({
            tickerData:    FirebaseDataTransformer.transformTickerData(newData),
            tickerDataRaw: newData,
        });
    };

    mannschaftenBereicheChanged = (data, initial) => {
        console.log('mannschaftenBereicheChanged', data);

        let newData = this.mergeChangeSet(
            this.state.mannschaftenBereicheDataRaw,
            data,
            initial,
        );

        newData = _.sortBy(
            newData,
            'data.score',
        ).reverse();

        let newData2 = this.mergeChangeSet(
            this.state.mannschaftenBereicheData2Raw,
            data,
            initial,
        );

        newData2 = _.sortBy(
            newData2,
            'data.score',
        ).reverse();

        this.setState({
            mannschaftenBereicheData:     FirebaseDataTransformer.transformMannschaftenBereicheData(newData),
            mannschaftenBereicheDataRaw:  newData,
            mannschaftenBereicheData2:    FirebaseDataTransformer.transformMannschaftenBereicheData2(newData2),
            mannschaftenBereicheData2Raw: newData2,
        });
    };

    spielerChanged = (data, initial) => {
        console.log('spielerChanged', data);

        let newData = this.mergeChangeSet(
            this.state.spielerDataRaw,
            data,
            initial,
        );

        this.setState({
            spielerData:    FirebaseDataTransformer.transformSpielerData(newData),
            spielerDataRaw: newData,
        });
    };

    ts7DataChanged = (data, initial) => {
        console.log('ts7DataChanged', data);

        let newData = this.mergeChangeSet(
            this.state.ts7DataRaw,
            data,
            initial,
        );

        this.setState({
            ts7Data:    FirebaseDataTransformer.transformTs7Data(newData),
            ts7DataRaw: newData,
        });
    };
}

const mapStateToProps = state => (
    {
        debugCount:   state.debug.debugCount,
        racingStable: state.game.racingStable,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    DebugActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Screen);
