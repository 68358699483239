import React        from 'react';
import styles       from './styles.module.scss';
import PropTypes    from '../../PropTypes';
import NumberFormat from '../../../helper/NumberFormat';
import I18n         from 'i18next';

class Component extends React.Component {
    updateTimer = null;

    componentDidMount () {
        this.updateTimer = window.setInterval(this.reRender, 1000);
    }

    componentWillUnmount () {
        if (this.updateTimer) {
            window.clearInterval(this.updateTimer);
        }
    }

    getTimeString = () => {
        const now          = new Date();
        const nowInSeconds = now.getTime() / 1000;
        const totalSeconds = this.props.toTimeStamp - nowInSeconds;

        if (totalSeconds < 0) {
            if (this.props.customRenderer) {
                return this.props.customRenderer(
                    0,
                    0,
                    0,
                    0,
                );
            }

            return 0;
        }

        const leadingZeroCount = this.props.showValueNames ? 0 : 2;
        const days             = Math.floor(totalSeconds / 86400);
        const hours            = NumberFormat.addLeadingZero(
            Math.floor(totalSeconds / 3600) % 24,
            leadingZeroCount,
        );
        const minutes          = NumberFormat.addLeadingZero(
            Math.floor(totalSeconds / 60) % 60,
            leadingZeroCount,
        );
        const seconds          = NumberFormat.addLeadingZero(
            Math.round(totalSeconds % 60),
            leadingZeroCount,
        );

        if (this.props.customRenderer) {
            return this.props.customRenderer(
                days,
                hours,
                minutes,
                seconds,
            );
        }

        const timeStringBuilder = [];

        if (days > 0) {
            timeStringBuilder.push(days);

            if (this.props.showValueNames) {
                timeStringBuilder.push(I18n.t(days === 1 ? 'countDownDay' : 'countDownDays'));
            }
        }

        timeStringBuilder.push(hours);

        if (this.props.showValueNames) {
            timeStringBuilder.push(I18n.t(hours === 1 ? 'countDownHour' : 'countDownHours'));
        }

        timeStringBuilder.push(minutes);

        if (this.props.showValueNames) {
            timeStringBuilder.push(I18n.t(minutes === 1 ? 'countDownMinute' : 'countDownMinutes'));
        }

        timeStringBuilder.push(seconds);

        if (this.props.showValueNames) {
            timeStringBuilder.push(I18n.t(seconds === 1 ? 'countDownSecond' : 'countDownSeconds'));
        }

        const timeString = timeStringBuilder.join(this.props.showValueNames ? ' ' : ':');

        return timeString;
    };

    render () {
        const timeString = this.getTimeString();

        return (
            <span className={styles.wrapper}>
                {timeString}
            </span>
        );
    }

    reRender = () => {
        this.setState({});
    };
}

Component.propTypes = {
    customRenderer: PropTypes.func,
    showValueNames: PropTypes.bool,
    toTimeStamp:    PropTypes.number,
};

Component.defaultProps = {
    customRenderer: null,
    showValueNames: false,
    toTimeStamp:    null,
};

export default Component;