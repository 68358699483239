import { GameTypes } from '../actions/game';
import update        from 'immutability-helper';

const initialState = {
    racingStable: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GameTypes.SELECT_RACING_STABLE:
            return update(state, {
                racingStable: {
                    $set: action.racingStable,
                },
            });

        default:
            return state;
    }
}