//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import FirebaseEndpoints from '../constants/FirebaseEndpoints';
import GameProvider      from './GameProvider';
import firebase          from 'firebase/app';
import 'firebase/firestore';

const initialCallbackCache = {};

class FirebaseDataProvider {
    static firestore;

    static getFireStore () {
        if (!FirebaseDataProvider.firestore) {
            FirebaseDataProvider.firestore = firebase.firestore();
        }

        return FirebaseDataProvider.firestore;
    }

    static mapData (document) {
        return {
            id:   document.id,
            data: document.data(),
        };
    }

    static mapChangeData (change) {
        return {
            type:     change.type,
            document: FirebaseDataProvider.mapData(change.doc),
        };
    }

    static collectionDataChangedCallback (snapshot, collectionName, dataChangedCallback) {
        if (initialCallbackCache[collectionName]) {
            const docChanges = snapshot.docChanges();
            const changeData = (
                docChanges.map(FirebaseDataProvider.mapChangeData)
            );

            dataChangedCallback(changeData, false);
        } else {
            initialCallbackCache[collectionName] = true;
            const data                           = (
                snapshot.docs.map(FirebaseDataProvider.mapData)
            );

            dataChangedCallback(data, true);
        }
    }

    static getBannerTextData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.bannerText;

        FirebaseDataProvider.getFireStore().collection(collectionName).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getDriverData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.driver;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'Gesamt',
            'desc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getConstructorData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.constructor;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'score',
            'desc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getFlashTableData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.flashTable;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'rang',
            'desc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getParallelGamesData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.parallelGames;

        FirebaseDataProvider.getFireStore().collection(collectionName).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getRacesTableData (dataChangedCallback) {
        const collectionName = GameProvider.getGameId();

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'rang',
            'desc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getTS7TableData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.ts7;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'vp',
            'asc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getRacingTimeData (dataChangedCallback) {
        const collectionName = GameProvider.getGameId();

        FirebaseDataProvider.getFireStore().collection(FirebaseEndpoints.times).onSnapshot((snapshot) => {
            const data = (
                snapshot.docs.map(FirebaseDataProvider.mapData)
            );

            dataChangedCallback(data);
        });
    }

    static getRacingStableTableData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.racingStable;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'Gesamt',
            'desc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getSquadsTableData (dataChangedCallback) {
        const firestore = firebase.firestore();

        // TODO: orderBy('rang_neu', 'asc')

        firestore.collection(FirebaseEndpoints.squads).onSnapshot((snapshot) => {
            const data = (
                snapshot.docs.map(FirebaseDataProvider.mapData)
            );

            dataChangedCallback(data);
        });
    }

    static getStatisticsData (dataChangedCallback) {
        FirebaseDataProvider.getFireStore().collection(FirebaseEndpoints.statistics).onSnapshot((snapshot) => {
            const data = (
                snapshot.docs.map(FirebaseDataProvider.mapData)
            );

            dataChangedCallback(data);
        });
    }

    static getTeamData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.teams;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'rang_neu',
            'asc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getSpecialJerseysData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.specialJerseys;

        FirebaseDataProvider.getFireStore().collection(collectionName).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getTickerData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.ticker;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'time',
            'desc',
        ).limit(50).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getMannschaftenBereicheData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.mannschaftenBereiche;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'rang_neu',
            'asc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getSpielerData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.spieler;

        FirebaseDataProvider.getFireStore().collection(collectionName).orderBy(
            'Gesamt',
            'desc',
        ).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }

    static getDraftData (dataChangedCallback) {
        const collectionName = FirebaseEndpoints.draft;

        FirebaseDataProvider.getFireStore().collection(collectionName).onSnapshot((snapshot) => {
            FirebaseDataProvider.collectionDataChangedCallback(
                snapshot,
                collectionName,
                dataChangedCallback,
            );
        });
    }
}

export default FirebaseDataProvider;
