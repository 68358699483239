import ComponentHelper         from '../../../helper/ComponentHelper';
import PropTypes               from '../../PropTypes';
import React                   from 'react';
import styles                  from './styles.module.scss';
import _                       from 'lodash';
import GameProvider            from '../../../helper/GameProvider';
import FirebaseDataTransformer from '../../../helper/FirebaseDataTransformer';

class GameDropDown extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    onChange = (event) => {
        const gameId = event.target.value;
        const url    = '/?spiel=' + gameId;

        window.location.href = url;
    };

    render () {
        const sortedData = _.sortBy(this.props.timeData, 'id');

        return (
            <div className={styles.wrapper}>
                <select
                    onChange={this.onChange}
                >
                    {sortedData.map(this.renderItem)}
                </select>
            </div>
        );
    }

    renderItem = (timeData) => {
        const currentGame = GameProvider.getGameId();

        return (
            <option
                selected={currentGame === timeData.id}
                value={timeData.id}
            >
                {FirebaseDataTransformer.beautifyGame(timeData.id)}
            </option>
        );
    };
}

const Component = GameDropDown;

Component.propTypes = {
    timeData: PropTypes.array,
};

Component.defaultProps = {
    timeData: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

export default Component;