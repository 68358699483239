//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import './language/Language';
import '@babel/polyfill';
import * as serviceWorker           from './serviceWorker';
import CacheInvalidator             from './components/stateless/CacheInvalidator';
import Header                       from './components/stateless/Header';
import Home                         from './screens/Home';
import Routes                       from './constants/Routes';
import NotFound                     from './screens/NotFound';
import React                        from 'react';
import ReactDOM                     from 'react-dom';
import store                        from './store';
import styles                       from './styles.module.scss';
import { ConnectedRouter }          from 'connected-react-router';
import { history }                  from './store/index';
import { Provider }                 from 'react-redux';
import { Route }                    from 'react-router';
import { Switch }                   from 'react-router';
import firebase                     from 'firebase/app';
import 'firebase/database';
import { FirebaseDatabaseProvider } from '@react-firebase/database';
import firebaseConfig               from './firebase/config';
import update                       from 'immutability-helper';
import ReactHintFactory             from 'react-hint';
import 'react-hint/css/index.css';

const ReactHint = ReactHintFactory(React);

update.extend('$increaseBy', (amount, value) => {
    return value + amount;
});

update.extend('$decreaseBy', (amount, value) => {
    return value - amount;
});

update.extend('$decreaseByStayPositive', (amount, value) => {
    if (value > 1) {
        return value - amount;
    }

    return value;
});

ReactDOM.render(
    <Provider store={store()}>
        <FirebaseDatabaseProvider firebase={firebase} {...firebaseConfig}>
            <CacheInvalidator />
            <ConnectedRouter history={history}>
                <Header />
                <ReactHint
                    autoPosition
                    events
                />
                <div className={styles.appContent}>
                    <Switch>
                        <Route
                            component={Home}
                            exact={true}
                            path={Routes.match}
                        />
                        <Route
                            component={Home}
                        />
                    </Switch>
                </div>
            </ConnectedRouter>
        </FirebaseDatabaseProvider>
    </Provider>
    , document.getElementById('root'),
);

serviceWorker.register();