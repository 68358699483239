import React     from 'react';
import styles    from './styles.module.scss';
import PropTypes from '../../PropTypes';
import CountDown from '../CountDown';
import I18n      from 'i18next';

class Component extends React.Component {
    render () {
        return (
            <CountDown
                customRenderer={this.renderCountDown}
                toTimeStamp={this.props.toTimeStamp}
            />
        );
    }

    renderCountDown = (days, hours, minutes, seconds) => {
        return (
            <span className={styles.wrapper}>
                {days ? (
                    <span className={styles.dayWrapper}>
                        <strong>
                            {days}
                        </strong>
                        {' '}
                        {I18n.t(days === 1 ? 'countDownDay' : 'countDownDays')}
                    </span>
                ) : null}
                <strong>
                    {hours}
                    {':'}
                    {minutes}
                    {':'}
                    {seconds}
                </strong>
                {' '}
                {I18n.t('countDownHours')}
            </span>
        );
    };
}

Component.propTypes = {
    toTimeStamp: PropTypes.number,
};

Component.defaultProps = {
    toTimeStamp: null,
};

export default Component;