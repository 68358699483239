import ComponentHelper from '../../../helper/ComponentHelper';
import React           from 'react';

export default function withWindowDimensions (WrappedComponent) {
    class ResizeListener extends React.Component {
        state = {
            windowHeight: 0,
            windowWidth:  0,
        };

        componentDidMount () {
            this.updateWindowDimensions();

            window.addEventListener('resize', this.updateWindowDimensions);
        }

        componentWillUnmount () {
            window.removeEventListener('resize', this.updateWindowDimensions);
        }

        shouldComponentUpdate (nextProps, nextState) {
            return ComponentHelper.shouldComponentUpdate(
                this,
                Component,
                nextProps,
                nextState,
            );
        }

        render () {
            return (
                <WrappedComponent
                    {...this.props}
                    windowWidth={this.state.windowWidth}
                    windowHeight={this.state.windowHeight}
                />
            );
        }

        updateWindowDimensions = () => {
            this.setState({
                windowHeight: window.innerHeight,
                windowWidth:  window.innerWidth,
            });
        };
    }

    const Component = ResizeListener;

    Component.renderAffectingProps = [];

    Component.renderAffectingStates = [
        'windowWidth',
        'windowHeight',
    ];

    return Component;
}