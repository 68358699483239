//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

const firebaseConfig = {
    apiKey:     'AIzaSyCR1VZSy_HLkXmS25oxVhiXkEf0OPqZC0I',
    authDomain: 'soccerdraft2022quartal4.firebaseapp.com',
    projectId:  'soccerdraft2022quartal4',
};

module.exports = firebaseConfig;
