//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import HeaderLogo              from '../../../assets/images/tour-de-france-logo.svg';
import FirebaseDataProvider    from '../../../helper/FirebaseDataProvider';
import FirebaseDataTransformer from '../../../helper/FirebaseDataTransformer';
import classNames              from 'classnames';
import _                       from 'lodash';
import ComponentHelper         from '../../../helper/ComponentHelper';
import I18n                    from 'i18next';
import React                   from 'react';
import styles                  from './styles.module.scss';
import CustomCountDown         from '../CustomCountDown';
import VersionNumber           from '../VersionNumber';
import DebugCounterWrapper     from '../../connected/DebugCounterWrapper';

class Header extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    constructor (props) {
        super(props);

        this.state = {
            bannerTextData:    [],
            bannerTextDataRaw: [],
            highlighted:       false,
            initialLoadDone:   false,
        };
    }

    componentDidMount () {
        FirebaseDataProvider.getBannerTextData(this.bannerTextTableDataChanged);
    }

    bannerTextTableDataChanged = (data, initial) => {
        console.log('bannerTextTableDataChanged', data);

        const newData = this.mergeChangeSet(
            this.state.bannerTextDataRaw,
            data,
            initial,
        );

        this.setState({
            bannerTextDataRaw: newData,
            highlighted:       this.state.initialLoadDone,
            initialLoadDone:   true,
        });

        if (this.state.initialLoadDone) {
            setTimeout(this.disableHighlight, 200);
            setTimeout(this.enableHighlight, 400);
            setTimeout(this.disableHighlight, 600);
        }
    };

    disableHighlight = () => {
        this.setState({
            highlighted: false,
        });
    };

    enableHighlight = () => {
        this.setState({
            highlighted: true,
        });
    };

    mergeChangeSet = (initialData, changeSetOrData, initial) => {
        let newData = [...initialData];

        if (initial) {
            newData = [...changeSetOrData];
        } else {
            for (const change of changeSetOrData) {
                switch (change.type) {
                    case 'added':
                        newData.unshift(change.document);

                        break;

                    case 'modified':
                        const targetIndexToEdit = _.findIndex(newData, { id: change.document.id });

                        newData[targetIndexToEdit] = change.document;

                        break;

                    case 'removed':
                        const targetIndexToRemove = _.findIndex(newData, { id: change.document.id });

                        newData.splice(targetIndexToRemove, 1);

                        break;
                }
            }
        }

        return newData;
    };

    render () {
        const bannerText = _.get(this.state, 'bannerTextDataRaw[0].data.Text');
        console.log('this.state.highlighted', this.state.highlighted);
        return (
            <div className={styles.wrapper}>
                <div
                    className={classNames(
                        styles.container,
                        {
                            [styles.containerHighlighted]: this.state.highlighted,
                        },
                    )}
                >
                    <div className={styles.left}>
                        <div className={styles.logoWrapper}>
                            {bannerText}
                        </div>
                    </div>
                    <div className={styles.center}>
                        <div className={styles.headerBadge}>
                            <img
                                src={require('../../../assets/images/bundesliga/bundesliga-logo.png')}
                            />
                        </div>
                    </div>
                    <div className={styles.right}>
                        <DebugCounterWrapper>
                            <VersionNumber />
                        </DebugCounterWrapper>
                    </div>
                </div>
            </div>
        );
    }
}

const Component = Header;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = [
    'bannerTextDataRaw',
    'highlighted',
];

export default Component;
