import IconType        from './IconType';
import PropTypes       from '../../PropTypes';
import React           from 'react';
import SelectionHelper from '../../../helper/SelectionHelper';
import styles          from './styles.module.scss';
import IconTooltip     from '../../../assets/icons/tooltip.svg';
import IconZoomIn      from '../../../assets/icons/zoomIn.svg';
import IconZoomOut     from '../../../assets/icons/zoomOut.svg';

class Component extends React.Component {
    render () {
        return (
            <span className={styles.icon}>
                {this.renderSvg()}
            </span>
        );
    }

    renderSvg = () => {
        return SelectionHelper.get(
            this.props.iconType,
            {
                [IconType.tooltip]: <IconTooltip />,
                [IconType.zoomIn]:  <IconZoomIn />,
                [IconType.zoomOut]: <IconZoomOut />,
            },
        );
    };
}

Component.propTypes = {
    iconType: PropTypes.oneOf(Object.values(IconType)),
};

Component.defaultProps = {
    iconType: null,
};

export default Component;