//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper        from '../../../helper/ComponentHelper';
import React                  from 'react';
import styles                 from './styles.module.scss';
import PropTypes              from '../../PropTypes';
import classNames             from 'classnames';
import TeamLogo               from '../TeamLogo';
import TableTheme             from './TableTheme';
import RowType                from './RowType';
import ResizeListener         from '../ResizeListener';
import Cast                   from '../../../helper/Cast';
import _                      from 'lodash';
import moment                 from 'moment';
import TableColor             from './TableColor';
import { bindActionCreators } from 'redux';
import { GameActions }        from '../../../store/actions/game';
import { connect }            from 'react-redux';

class Table extends React.Component {
    tableReference = null;

    /**
     * This fixes the table header row width since the browser rendering fails
     * because we gave tbody a overflow to make it possible to scroll the content.
     */
    fixTableHeaderColumnWidth = () => {
        if (this.tableReference) {
            const wrapper          = this.tableReference.parentNode;
            const tableBody        = this.tableReference.querySelector('tbody');
            const firstBodyCells   = tableBody.querySelectorAll('tr:first-child td');
            const tableHeader      = this.tableReference.querySelector('thead');
            const headerCells      = tableHeader.querySelectorAll('tr th');
            const columnWidths     = [];
            tableBody.style.height = Cast.pixelString(
                wrapper.getBoundingClientRect().height -
                tableHeader.getBoundingClientRect().height,
            );

            for (const firstBodyCell of firstBodyCells) {
                columnWidths.push(firstBodyCell.offsetWidth);
            }

            for (let headerCellIndex = 0; headerCellIndex < headerCells.length; ++headerCellIndex) {
                const headerCell             = headerCells[headerCellIndex];
                const widthFromColumnSetting = this.props.columns[headerCellIndex].width;
                let widthToSet               = columnWidths[headerCellIndex];

                if (widthFromColumnSetting && widthToSet < widthFromColumnSetting) {
                    widthToSet = widthFromColumnSetting;
                }

                headerCell.style.width = Cast.pixelString(widthToSet);
            }
        }
    };

    render () {
        console.log('Table: render');

        this.fixTableHeaderColumnWidth();

        return (
            <div className={styles.wrapper}>
                <table
                    cellPadding={0}
                    cellSpacing={0}
                    className={classNames(
                        styles.table,
                        {
                            [styles.blackTranslucent]: this.props.color === TableColor.blackTranslucent,
                            [styles.hasPaddingTop]:    this.props.hasPaddingTop,
                            [styles.white]:            this.props.color === TableColor.white,
                        },
                    )}
                    ref={this.setTableReference}
                >
                    <thead className={styles.tableHeader}>
                        <tr>
                            {this.props.columns.map(this.renderHeaderCell)}
                        </tr>
                    </thead>
                    <tbody className={styles.tableBody}>
                        {this.props.data.map(this.renderBodyRow)}
                    </tbody>
                </table>
            </div>
        );
    }

    renderBodyDashCell = (data, cellIndex) => {
        return (
            <td
                className={styles.tableBodyCellDash}
                colSpan={this.props.columns.length}
            >
                <div
                    className={classNames(
                        styles.tableBodyCellDashContent,
                        {
                            // @formatter:off
                            [styles.tableBodyCellDashContentBlackTranslucent]: this.props.color === TableColor.blackTranslucent,
                            [styles.tableBodyCellDashContentWhite]:            this.props.color === TableColor.white,
                            // @formatter:on
                        },
                    )}
                />
            </td>
        );
    };

    renderBodyTextCell = (data, columnIndex) => {
        const cellStyle = {
            fontWeight: this.props.columns[columnIndex].fontWeight,
        };

        if (this.props.fullWidthColumnIndex === columnIndex) {
            cellStyle.width = '100%';
        }

        const key                             = 'bodyTextCell' + columnIndex;
        const innerTextStyle                  = {};
        const innerTextWidthFromColumnSetting = this.props.columns[columnIndex].innerTextWidth;
        const widthFromColumnSetting          = this.props.columns[columnIndex].width;

        if (widthFromColumnSetting) {
            innerTextStyle.width = Cast.pixelString(widthFromColumnSetting);
        } else if (innerTextWidthFromColumnSetting) {
            innerTextStyle.width = Cast.pixelString(innerTextWidthFromColumnSetting);
        }

        const maxTextLength = this.props.columns[columnIndex].maxTextLength;
        let tooltip         = null;
        let text            = data.text;

        if (
            text &&
            maxTextLength &&
            text.length > maxTextLength
        ) {
            text    = text.substr(0, maxTextLength) + '...';
            tooltip = data.text;
        }

        return (
            <td
                className={classNames(
                    styles.tableBodyCellText,
                    {
                        [styles.tableBodyCellTextWithImage]:              data.preImage,
                        [styles.tableBodyCellTextBig]:              this.props.theme === TableTheme.big,
                        [styles.tableBodyCellTextFullWidth]:        this.props.fullWidthColumnIndex === columnIndex,
                        [styles.tableBodyCellTextFullWidthAfter]:   this.props.fullWidthColumnIndex === columnIndex - 1,
                        [styles.tableBodyCellTextSmall]:            this.props.theme === TableTheme.small,
                        [styles.tableBodyCellTextBlackTranslucent]: this.props.color === TableColor.blackTranslucent,
                        [styles.tableBodyCellTextWhite]:            this.props.color === TableColor.white,
                    },
                )}
                key={key}
                style={cellStyle}
            >
                <span
                    className={classNames(
                        styles.tableBodyCellTextInner,
                        {
                            // @formatter:off
                            [styles.tableBodyCellTextInnerKeepDash]:         data.keepDash,
                            [styles.tableBodyCellTextInnerBlackTranslucent]: this.props.color === TableColor.blackTranslucent,
                            [styles.tableBodyCellTextInnerContentWhite]:     this.props.color === TableColor.white,
                            // @formatter:on
                        },
                    )}
                    data-rh={tooltip}
                    style={innerTextStyle}
                >
                    {this.renderBodyTextCellPreImage(data)}
                    {text}
                    {this.renderBodyTextCellPostImage(data)}
                </span>
            </td>
        );
    };

    renderBodyTextCellPreImage = (data) => {
        if (data.preImage) {
            return (
                <TeamLogo
                    teamImage={data.preImage}
                    teamName={data.text}
                />
            );
        }

        return null;
    };

    renderBodyTextCellPostImage = (data) => {
        if (data.postImage) {
            return (
                <TeamLogo
                    teamImage={data.postImage}
                    teamName={data.text}
                />
            );
        }

        return null;
    };

    renderHeaderCell = (data, cellIndex) => {
        const key = 'headerCell' + cellIndex;

        return (
            <th
                className={classNames(
                    styles.tableHeaderCell,
                    {
                        [styles.tableHeaderCellBig]:   this.props.theme === TableTheme.big,
                        [styles.tableHeaderCellSmall]: this.props.theme === TableTheme.small,
                        [styles.tableHeaderClickable]: data.onClick,
                    },
                )}
                key={key}
                onClick={data.onClick}
            >
                {data.title}
            </th>
        );
    };

    renderBodyRow = (rowData, rowIndex) => {
        const key = 'bodyRow' + rowIndex;

        switch (rowData.type) {
            case RowType.dash:
                return (
                    <tr
                        className={styles.tableBodyRowDash}
                        key={key}
                    >
                        {this.renderBodyDashCell()}
                    </tr>
                );

            case RowType.text:
                return (
                    <tr
                        className={classNames(
                            styles.tableBodyRowText,
                            {
                                // @formatter:off
                                [styles.tableBodyRowTextChanged]:          this.rowHasChangedData(rowData),
                                [styles.tableBodyRowTextBlackTranslucent]: this.props.color === TableColor.blackTranslucent,
                                [styles.tableBodyRowTextWhite]:            this.props.color === TableColor.white,
                                [styles.tableBodyRowTextGroupEnd]:         rowData.groupEnd,
                                [styles.tableBodyRowTextGroupStart]:       rowData.groupStart,
                                [styles.tableBodyRowTextSelected]:         (
                                                                               this.props.allowHighlighting &&
                                                                               (
                                                                                   rowData.selected ||
                                                                                       _.find(rowData.cellData, { text: this.props.racingStable  })
                                                                               )
                                                                           ),
                                [styles.tableBodyRowTextClickable]:        rowData.targetRoute,
                                // @formatter:on
                            },
                        )}
                        key={key}
                        onClick={() => {
                            if (this.props.onRowClick) {
                                this.props.onRowClick(rowData);
                            }
                        }}
                    >
                        {rowData.cellData.map(this.renderBodyTextCell)}
                    </tr>
                );
        }
    };

    rowHasChangedData = (rowData) => {
        const changeThreshold = moment().unix() - 1000;

        // We use the current time since the table is re-rendered multiple times
        // so we cannot toggle a boolean because it would be overwritten after
        // a few milliseconds.
        const rowHasChangedData = (
            !rowData.selected &&
            rowData.lastChangeDate &&
            rowData.lastChangeDate > changeThreshold
        );

        return rowHasChangedData;
    };

    setTableReference = (reference) => {
        this.tableReference = reference;
    };

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = Table;

Component.propTypes = {
    allowHighlighting:    PropTypes.bool,
    color:                PropTypes.oneOf(Object.values(TableColor)),
    columns:              PropTypes.array,
    data:                 PropTypes.array,
    fullWidthColumnIndex: PropTypes.number,
    hasPaddingTop:        PropTypes.bool,
    onRowClick:           PropTypes.func,
    rows:                 PropTypes.array,
    theme:                PropTypes.oneOf(Object.values(TableTheme)),
    windowHeight:         PropTypes.number,
    windowWidth:          PropTypes.number,
};

Component.defaultProps = {
    allowHighlighting:    true,
    color:                TableColor.white,
    columns:              [],
    data:                 [],
    fullWidthColumnIndex: 0,
    hasPaddingTop:        false,
    onRowClick:           null,
    rows:                 [],
    theme:                TableTheme.small,
    windowHeight:         null,
    windowWidth:          null,
};

Component.renderAffectingProps = [
    'color',
    'columns',
    'data',
    'fullWidthColumnIndex',
    'rows',
    'theme',
    'windowHeight',
    'windowWidth',
];

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        racingStable: state.game.racingStable,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    GameActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ResizeListener(Component));
