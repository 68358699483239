//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper              from '../../../helper/ComponentHelper';
import I18n                         from 'i18next';
import React                        from 'react';
import styles                       from './styles.module.scss';
import TimeCountdownGameInfoOverlay from '../TimeCountdownGameInfoOverlay';
import Icon                         from '../Icon';
import IconType                     from '../Icon/IconType';
import GameInfoOverlay              from '../GameInfoOverlay';
import RacingStableDropDown         from '../../connected/RacingStableDropDown';
import GameDropDown                 from '../../stateless/GameDropDown';
import Cast                         from '../../../helper/Cast';
import GameProvider                 from '../../../helper/GameProvider';
import GameInfoOverlayAlignment     from '../GameInfoOverlay/GameInfoOverlayAlignment';
import CustomCountDown              from '../CustomCountDown';
import PropTypes                    from '../../PropTypes';
import RaceTrack                    from '../../../assets/images/americas-cup/game-v5-min.svg';
import ShirtBar                     from '../../../assets/images/americas-cup/shirt-bar-v2.svg';
import panAndZoomHoc                from 'react-pan-and-zoom-hoc';
import { bindActionCreators }       from 'redux';
import { GameActions }              from '../../../store/actions/game';
import { connect }                  from 'react-redux';
import classNames                   from 'classnames';
import _                            from 'lodash';

const InteractiveDiv = panAndZoomHoc('div');

const scaleBounds = {
    max: 3.0,
    min: 0.5,
};

const constructorCarMap = {
    1:  'yacht_america',
    2:  'yacht_magic',
    3:  'yacht_madleine',
    4:  'yacht_mischief',
    5:  'yacht_puritan',
    6:  'yacht_mayflower',
    7:  'yacht_volunteer',
    8:  'yacht_vigilant',
    9:  'yacht_defender',
    10: 'yacht_columbia',
    11: 'yacht_reliance',
    12: 'yacht_resolute',
    13: 'yacht_enterprise',
    14: 'yacht_rainbow',
    15: 'yacht_ranger',
    16: 'yacht_weatherly',
    17: 'yacht_constellation',
    18: 'yacht_intrepid',
    19: 'yacht_courageous',
    20: 'yacht_freedom',
    21: 'yacht_liberty',
    22: 'yacht_kookaburra_iii',
    23: 'yacht_stars_stripes',
    24: 'yacht_young_america',
    26: 'yacht_team_new_zealand',
    27: 'yacht_new_zealand',
    28: 'yacht_alinghi',
    29: 'yacht_te_rehutai',
    30: 'yacht_cambria',
    31: 'yacht_livonia',
    32: 'yacht_countess_of_duffer',
    33: 'yacht_atalanta',
    34: 'yacht_aurora',
    35: 'yacht_genesta',
    36: 'yacht_galatea',
    37: 'yacht_thistle',
    38: 'yacht_alarm',
    39: 'yacht_arrow',
    40: 'yacht_valkyrie',
    41: 'yacht_shamrock',
    42: 'yacht_gretel',
    43: 'yacht_dame_pattie',
    44: 'yacht_southern_cross',
    45: 'yacht_endeaevour',
    46: 'yacht_sovereign',
    47: 'yacht_australia',
    48: 'yacht_il_moro_di_venezi',
};

const carDomCache   = {};
const trackDomCache = {};

class F1RaceTrack extends React.Component {
    changeScaleBy = (scaleChange) => {
        const nextScale = this.getScaleWithBoundaries(this.state.scale + scaleChange);

        this.setState({
            scale: nextScale,
        });
    };

    constructor (props) {
        super(props);

        this.state = {
            x:           0.5,
            y:           0.72, // The higher the value the higher the start
            scale:       1.10,
            showNumbers: false,
        };
    }

    getCarFromDom (carId) {
        if (!carDomCache[carId]) {
            carDomCache[carId] = document.getElementById(carId);
        }

        return carDomCache[carId];
    }

    getScaleWithBoundaries = (scale) => {
        return Math.min(scaleBounds.max, Math.max(scaleBounds.min, scale));
    };

    getTrackFromDom (trackId) {
        if (!trackDomCache[trackId]) {
            trackDomCache[trackId] = document.getElementById(trackId);
        }

        return trackDomCache[trackId];
    }

    getPointAtLengthWithRotation (path, length) {
        const point1 = path.getPointAtLength(length);
        const point2 = path.getPointAtLength(length - 30);
        const deg    = Math.atan2(point1.y - point2.y, point1.x - point2.x) * (
            180 / Math.PI
        );

        return {
            x:     point1.x,
            y:     point1.y,
            angle: deg,
        };
    }

    moveCarToPositionOnTrack (positionInPercent, carId, trackId, scale, carTextWrapper) {
        const path = this.getTrackFromDom(trackId);

        if (path) {
            const pathLength        = path.getTotalLength();
            const point             = path.getPointAtLength(positionInPercent * pathLength);
            const pointWithRotation = this.getPointAtLengthWithRotation(path, positionInPercent * pathLength);
            const car               = this.getCarFromDom(carId);
            const angleCorrection   = -90;

            console.log('carId', carId);

            car.setAttribute(
                'transform',
                'translate(' + (
                    point.x
                ) + ',' + (
                    point.y
                ) + ') scale(' + scale + ') rotate(' + (
                    pointWithRotation.angle + angleCorrection
                ) + ' 0 0)',
            );
            carTextWrapper.setAttribute(
                'transform',
                'translate(6, 0) rotate(' + (
                    360 - (
                        pointWithRotation.angle + angleCorrection
                    )
                ) + ' 0 0)',
            );
            carTextWrapper.setAttribute(
                'transform-origin',
                'center',
            );
            carTextWrapper.setAttribute('opacity', this.state.showNumbers ? 1 : 0);
            car.setAttribute('opacity', 1);
        } else {
            console.error('Track not found in svg: ', trackId, carId);
        }
    }

    componentDidMount () {
        const track1        = this.getTrackFromDom('Track1');
        const track2        = this.getTrackFromDom('Track2');
        const track3        = this.getTrackFromDom('Track3');
        const tracksVisible = false;

        if (!tracksVisible) {
            if (track1) {
                track1.setAttribute('opacity', 0);
            }

            if (track2) {
                track2.setAttribute('opacity', 0);
            }

            if (track3) {
                track3.setAttribute('opacity', 0);
            }
        }

        for (const carId of Object.values(constructorCarMap)) {
            const car = this.getTrackFromDom(carId);

            if (car) {
                car.setAttribute('opacity', 0);
            } else {
                console.error('Car not found in svg:', carId);
            }
        }
    }

    calculateMeterFixedForData = (data, trackId) => {
        let meterFixed = Math.floor(data.meter);

        switch (trackId) {
            // @formatter:off
            case 'Track1': meterFixed += 1; break;
            case 'Track2': meterFixed += 1; break;
            case 'Track3': meterFixed += 1; break;
            // @formatter:on
        }

        // Only for tour de france and americas cup since the tracks are flipped
        meterFixed = 100 - meterFixed;

        if (meterFixed > 100) {
            meterFixed -= 100;
        }

        return meterFixed;
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
        if (prevProps === this.props) {
            return;
        }

        for (const raceDataEntryKey in this.props.racesData) {
            const raceDataEntryOld = prevProps.racesData[raceDataEntryKey];
            const raceDataEntry    = this.props.racesData[raceDataEntryKey];
            const dataOld          = _.get(raceDataEntryOld, 'data');
            const data             = raceDataEntry.data;
            const carId            = constructorCarMap[data.konstrukteur_nr];
            const trackId          = 'Track' + (
                (
                    data.konstrukteur_nr % 3
                ) + 1
            );

            const dataMeterFixed  = this.calculateMeterFixedForData(data, trackId);
            let dataOldMeterFixed = 0;

            if (dataOld) {
                dataOldMeterFixed = this.calculateMeterFixedForData(dataOld, trackId);
            }

            const progressInPercent    = dataMeterFixed / 100;
            const progressInPercentOld = (
                dataOld ?
                    dataOldMeterFixed / 100 :
                    -1
            );
            const scale                = (
                raceDataEntry.id !== this.props.racingStable ?
                    1 :
                    1.6
            );

            const car = this.getCarFromDom(carId);

            if (car) {
                const carTextWrapper = car.querySelector('#nummer');
                const carText        = car.querySelector('text tspan');

                if (carText) {
                    const rangFixed = data.rang < 10 ? '0' + data.rang : data.rang;

                    carText.textContent = rangFixed;
                }

                if (progressInPercentOld > 0) {
                    let timeout = 0;
                    const self  = this;

                    for (
                        let currentProgress = progressInPercentOld;
                        currentProgress <= progressInPercent;
                        currentProgress += 0.005
                    ) {
                        window.setTimeout(
                            function () {
                                self.moveCarToPositionOnTrack(currentProgress, carId, trackId, scale, carTextWrapper);
                            },
                            timeout,
                        );

                        timeout += 50;
                    }
                } else {
                    this.moveCarToPositionOnTrack(progressInPercent, carId, trackId, scale, carTextWrapper);
                }
            }
        }
    }

    getGameDay = () => {
        const game            = GameProvider.getGameId();
        const gameDayInternal = game.replace('spiel', '');
        const gameDay         = Cast.int(gameDayInternal);

        return gameDay;
    };

    getZoomLevel = () => {
        return Math.round(this.state.scale * 100);
    };

    handlePanAndZoom (x, y, scale) {
        this.setState({
            x,
            y,
            scale,
        });
    }

    handlePanMove (x, y) {
        this.setState({
            x,
            y,
        });
    }

    render () {
        const { x, y, scale } = this.state;
        const width           = 1344;
        const height          = 759;
        const transformedAxis = this.transformPoint({
            x: 0.5,
            y: 0.5,
        });

        return (
            <div className={styles.wrapper}>
                {this.renderZoomControls()}
                {this.renderShirtBar()}
                <div className={styles.top}>
                    <GameDropDown timeData={this.props.timeData} />
                    <RacingStableDropDown racesData={this.props.racesData} />
                    <CustomCountDown
                        toTimeStamp={this.props.endTime.seconds}
                    />
                </div>
                <div className={styles.raceTrackWrapper}>
                    {this.renderGoalOverlay()}
                    <InteractiveDiv
                        x={x}
                        y={y}
                        scale={scale}
                        scaleFactor={Math.sqrt(1.04)}
                        minScale={1}
                        className={styles.zoomControl}
                        maxScale={2 * 18}
                        onPanAndZoom={(x, y, scale) => this.handlePanAndZoom(x, y, scale)}
                        onPanMove={(x, y) => this.handlePanMove(x, y)}
                    >
                        <div
                            className={styles.raceTrack}
                        >
                            <RaceTrack
                                style={{
                                    position:   'absolute',
                                    marginLeft: width / 2 * -1 * scale,
                                    marginTop:  height / 2 * -1 * scale,
                                    top:        `${transformedAxis.y * 100}%`,
                                    left:       `${transformedAxis.x * 100}%`,
                                    height:     height * scale,
                                    width:      width * scale,
                                }}
                            />
                        </div>
                    </InteractiveDiv>
                </div>
            </div>
        );
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    transformPoint ({ x, y }) {
        return {
            x: 0.5 + this.state.scale * (
                x - this.state.x
            ),
            y: 0.5 + this.state.scale * (
                y - this.state.y
            ),
        };
    }

    renderGoalOverlay = () => {
        return (
            <div className={styles.goalOverlay}>
                {this.props.goalProgress}
                /
                {this.props.goal}
            </div>
        );
    };

    renderZoomControls = () => {
        return (
            <div className={styles.zoomAndPanControlButtons}>
                {this.renderZoomLevel()}
                <span
                    className={classNames(
                        styles.zoomAndPanControlButton,
                        styles.zoomAndPanControlButtonZoomOut,
                    )}
                    onClick={this.zoomOutButtonPressed}
                >
                    <Icon iconType={IconType.zoomOut} />
                </span>
                <span
                    className={classNames(
                        styles.zoomAndPanControlButton,
                        styles.zoomAndPanControlButtonZoomIn,
                    )}
                    onClick={this.zoomInButtonPressed}
                >
                    <Icon iconType={IconType.zoomIn} />
                </span>
                <span
                    className={classNames(
                        styles.zoomAndPanControlButton,
                        styles.zoomAndPanControlButtonShowNumbers,
                        {
                            [styles.zoomAndPanControlButtonShowNumbersActive]: this.state.showNumbers,
                        },
                    )}
                    onClick={this.showNumbersButtonPressed}
                >
                    <Icon iconType={IconType.tooltip} />
                </span>
            </div>
        );
    };

    renderShirtBar = () => {
        const specialJerseysData = this.props.specialJerseysData;
        const green              = _.find(specialJerseysData, { id: 'grün' });
        const red                = _.find(specialJerseysData, { id: 'rot' });
        const yellow             = _.find(specialJerseysData, { id: 'gelb' });
        const white              = _.find(specialJerseysData, { id: 'weiss' });
        const getPath            = 'data.vp';

        return (
            <div className={styles.shirtBarWrapper}>
                <ShirtBar />
                <div className={styles.shirtBarWrapperDataWrapper}>
                    <ul>
                        <li>
                            {_.get(yellow, getPath, '-')}
                        </li>
                        <li>
                            {_.get(green, getPath, '-')}
                        </li>
                        <li>
                            {_.get(red, getPath, '-')}
                        </li>
                        <li>
                            {_.get(white, getPath, '-')}
                        </li>
                    </ul>
                </div>
            </div>
        );
    };

    renderZoomLevel = () => {
        const zoomLevel = this.getZoomLevel();

        if (zoomLevel !== 100) {
            return (
                <span className={styles.zoomAndPanControlZoomLevel}>
                    {zoomLevel}%
                </span>
            );
        }

        return null;
    };

    zoomIn = () => {
        this.changeScaleBy(0.2);
    };

    showNumbersButtonPressed = () => {
        this.setState({
            showNumbers: !this.state.showNumbers,
        }, this.updateCarNumberOpacity);
    };

    updateCarNumberOpacity = () => {
        for (const raceDataEntryKey in this.props.racesData) {
            const raceDataEntry = this.props.racesData[raceDataEntryKey];
            const data          = raceDataEntry.data;
            const carId         = constructorCarMap[data.konstrukteur_nr];
            const car           = this.getCarFromDom(carId);

            if (car) {
                const carTextWrapper = car.querySelector('#nummer');

                if (carTextWrapper) {
                    carTextWrapper.setAttribute('opacity', this.state.showNumbers ? 1 : 0);
                }
            }
        }
    };

    zoomInButtonPressed = () => {
        this.zoomIn();
    };

    zoomOut = () => {
        this.changeScaleBy(-0.2);
    };

    zoomOutButtonPressed = () => {
        this.zoomOut();
    };
}

const Component = F1RaceTrack;

Component.propTypes = {
    endTime:            PropTypes.string,
    goal:               PropTypes.number,
    goalProgress:       PropTypes.number,
    specialJerseysData: PropTypes.array,
    timeData:           PropTypes.array,
    racesData:          PropTypes.array,
    racingStable:       PropTypes.string,
};

Component.defaultProps = {
    endTime:            '',
    goal:               0,
    goalProgress:       0,
    specialJerseysData: [],
    timeData:           [],
    racesData:          [],
    racingStable:       '',
};

Component.renderAffectingProps = Object.keys(Component.propTypes);

Component.renderAffectingStates = [
    'scale',
    'showNumbers',
    'timeData',
    'racesData',
    'x',
    'y',
];
// TODO: in connected ordner schieben

const mapStateToProps = state => (
    {
        racingStable: state.game.racingStable,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    GameActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
