//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import TeamImage       from './TeamImage';
import SelectionHelper from '../../../helper/SelectionHelper';

// Team images
import team1  from '../../../assets/images/bundesliga/teams/1.png';
import team2  from '../../../assets/images/bundesliga/teams/2.png';
import team3  from '../../../assets/images/bundesliga/teams/3.png';
import team4  from '../../../assets/images/bundesliga/teams/4.png';
import team5  from '../../../assets/images/bundesliga/teams/5.png';
import team6  from '../../../assets/images/bundesliga/teams/6.png';
import team7  from '../../../assets/images/bundesliga/teams/7.png';
import team8  from '../../../assets/images/bundesliga/teams/8.png';
import team9  from '../../../assets/images/bundesliga/teams/9.png';
import team10 from '../../../assets/images/bundesliga/teams/10.png';
import team11 from '../../../assets/images/bundesliga/teams/11.png';
import team12 from '../../../assets/images/bundesliga/teams/12.png';
import team13 from '../../../assets/images/bundesliga/teams/13.png';
import team14 from '../../../assets/images/bundesliga/teams/14.png';
import team15 from '../../../assets/images/bundesliga/teams/15.png';
import team16 from '../../../assets/images/bundesliga/teams/16.png';
import team17 from '../../../assets/images/bundesliga/teams/17.png';
import team18 from '../../../assets/images/bundesliga/teams/18.png';
import team19 from '../../../assets/images/bundesliga/teams/19.png';
import team20 from '../../../assets/images/bundesliga/teams/20.png';
import team21 from '../../../assets/images/bundesliga/teams/21.png';
import team22 from '../../../assets/images/bundesliga/teams/22.png';
import team23 from '../../../assets/images/bundesliga/teams/23.png';
import team24 from '../../../assets/images/bundesliga/teams/24.png';
import team25 from '../../../assets/images/bundesliga/teams/25.png';
import team26 from '../../../assets/images/bundesliga/teams/26.png';
import team27 from '../../../assets/images/bundesliga/teams/27.png';
import team28 from '../../../assets/images/bundesliga/teams/28.png';
import team29 from '../../../assets/images/bundesliga/teams/29.png';
import team30 from '../../../assets/images/bundesliga/teams/30.png';
import team31 from '../../../assets/images/bundesliga/teams/31.png';
import team32 from '../../../assets/images/bundesliga/teams/32.png';
import team33 from '../../../assets/images/bundesliga/teams/33.png';
import team34 from '../../../assets/images/bundesliga/teams/34.png';
import team35 from '../../../assets/images/bundesliga/teams/35.png';
import team36 from '../../../assets/images/bundesliga/teams/36.png';
import team37 from '../../../assets/images/bundesliga/teams/37.png';
import team38 from '../../../assets/images/bundesliga/teams/38.png';
import team39 from '../../../assets/images/bundesliga/teams/39.png';
import team40 from '../../../assets/images/bundesliga/teams/40.png';
import team41 from '../../../assets/images/bundesliga/teams/41.png';
import team42 from '../../../assets/images/bundesliga/teams/42.png';
import team43 from '../../../assets/images/bundesliga/teams/43.png';
import team44 from '../../../assets/images/bundesliga/teams/44.png';
import team45 from '../../../assets/images/bundesliga/teams/45.png';
import team46 from '../../../assets/images/bundesliga/teams/46.png';
import team47 from '../../../assets/images/bundesliga/teams/47.png';
import team48 from '../../../assets/images/bundesliga/teams/48.png';
import team49 from '../../../assets/images/bundesliga/teams/49.png';
import team50 from '../../../assets/images/bundesliga/teams/50.png';
import team51 from '../../../assets/images/bundesliga/teams/51.png';
import team52 from '../../../assets/images/bundesliga/teams/52.png';
import team53 from '../../../assets/images/bundesliga/teams/53.png';
import team54 from '../../../assets/images/bundesliga/teams/54.png';
import team55 from '../../../assets/images/bundesliga/teams/55.png';
import team56 from '../../../assets/images/bundesliga/teams/56.png';
import team57 from '../../../assets/images/bundesliga/teams/57.png';
import team58 from '../../../assets/images/bundesliga/teams/58.png';
import team59 from '../../../assets/images/bundesliga/teams/59.png';
import team60 from '../../../assets/images/bundesliga/teams/60.png';
import team61 from '../../../assets/images/bundesliga/teams/61.png';
import team62 from '../../../assets/images/bundesliga/teams/62.png';
import team63 from '../../../assets/images/bundesliga/teams/63.png';
import team64 from '../../../assets/images/bundesliga/teams/64.png';
import team65 from '../../../assets/images/bundesliga/teams/65.png';
import team66 from '../../../assets/images/bundesliga/teams/66.png';
import team67 from '../../../assets/images/bundesliga/teams/67.png';
import team68 from '../../../assets/images/bundesliga/teams/68.png';
import team69 from '../../../assets/images/bundesliga/teams/69.png';
import team70 from '../../../assets/images/bundesliga/teams/70.png';
import team71 from '../../../assets/images/bundesliga/teams/71.png';
import team72 from '../../../assets/images/bundesliga/teams/72.png';
import team73 from '../../../assets/images/bundesliga/teams/73.png';
import team74 from '../../../assets/images/bundesliga/teams/74.png';
import team75 from '../../../assets/images/bundesliga/teams/75.png';
import team76 from '../../../assets/images/bundesliga/teams/76.png';
import team77 from '../../../assets/images/bundesliga/teams/77.png';
import team78 from '../../../assets/images/bundesliga/teams/78.png';
import team79 from '../../../assets/images/bundesliga/teams/79.png';
import team80 from '../../../assets/images/bundesliga/teams/80.png';
import team81 from '../../../assets/images/bundesliga/teams/81.png';
import team82 from '../../../assets/images/bundesliga/teams/82.png';
import team83 from '../../../assets/images/bundesliga/teams/83.png';
import team84 from '../../../assets/images/bundesliga/teams/84.png';
import team85 from '../../../assets/images/bundesliga/teams/85.png';
import team86 from '../../../assets/images/bundesliga/teams/86.png';
import team87 from '../../../assets/images/bundesliga/teams/87.png';
import team88 from '../../../assets/images/bundesliga/teams/88.png';
import team89 from '../../../assets/images/bundesliga/teams/89.png';
import team90 from '../../../assets/images/bundesliga/teams/90.png';
import team91 from '../../../assets/images/bundesliga/teams/91.png';
import team92 from '../../../assets/images/bundesliga/teams/92.png';
import team93 from '../../../assets/images/bundesliga/teams/93.png';

const teamImages = [
    team1,
    team2,
    team3,
    team4,
    team5,
    team6,
    team7,
    team8,
    team9,
    team10,
    team11,
    team12,
    team13,
    team14,
    team15,
    team16,
    team17,
    team18,
    team19,
    team20,
    team21,
    team22,
    team23,
    team24,
    team25,
    team26,
    team27,
    team28,
    team29,
    team30,
    team31,
    team32,
    team33,
    team34,
    team35,
    team36,
    team37,
    team38,
    team39,
    team40,
    team41,
    team42,
    team43,
    team44,
    team45,
    team46,
    team47,
    team48,
    team49,
    team50,
    team51,
    team52,
    team53,
    team54,
    team55,
    team56,
    team57,
    team58,
    team59,
    team60,
    team61,
    team62,
    team63,
    team64,
    team65,
    team66,
    team67,
    team68,
    team69,
    team70,
    team71,
    team72,
    team73,
    team74,
    team75,
    team76,
    team77,
    team78,
    team79,
    team80,
    team81,
    team82,
    team83,
    team84,
    team85,
    team86,
    team87,
    team88,
    team89,
    team90,
    team91,
    team92,
    team93,
];

class Component extends React.Component {

    render () {
        return (
            <div className={styles.wrapper}>
                {this.renderTeamImage()}
            </div>
        );
    }

    renderTeamImage = () => {
        const imageResource = teamImages[this.props.teamImage - 1];

        return (
            <img
                alt={this.props.teamName}
                src={imageResource}
            />
        );
    };
}

Component.propTypes = {
    teamImage: PropTypes.oneOf(Object.values(TeamImage)),
    teamName:  PropTypes.string,
};

Component.defaultProps = {
    teamImage: null,
    teamName:  null,
};

export default Component;
