import { combineReducers } from 'redux';
import { connectRouter }   from 'connected-react-router';
import debugReducer        from './debug';
import gameReducer         from './game';

export default (history) => combineReducers({
    debug:  debugReducer,
    game:   gameReducer,
    // This key must be "router"
    // @see https://github.com/supasate/connected-react-router
    router: connectRouter(history),
})