//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
// 
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
// 
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import React           from 'react';
import styles          from './styles.module.scss';
import PropTypes       from '../../PropTypes';
import I18n            from 'i18next';
import moment          from 'moment';
import ComponentHelper from '../../../helper/ComponentHelper';

class CurrentTimeRaw extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            currentTimeString: '0',
        };
    }

    componentDidMount () {
        setInterval(
            () => {
                this.setState({
                    currentTimeString: moment().format('HH:mm'),
                });
            },
            1000,
        );
    }

    render () {
        return this.state.currentTimeString;
    }

    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }
}

const Component = CurrentTimeRaw;

Component.propTypes = {};

Component.defaultProps = {};

Component.renderAffectingProps = [];

Component.renderAffectingStates = ['currentTimeString'];

export default Component;
