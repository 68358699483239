//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

export default {
    // TODO: Diese Tabellennamen sind richtig kacke und folgen keiner Struktur + sind deutsch...
    bannerText:           'bannerText/',
    constructor:          'konstrukteur/',
    driver:               'fahrer/',
    flashTable:           'blitztabelle/',
    parallelGames:        'paralleleSpiele/',
    spieler:              'spieler/',
    races:                'rennen/',
    racingStable:         'rennstall/',
    squads:               'kader/',
    specialJerseys:       'Sondertrikots/',
    statistics:           'statistik/',
    teams:                'mannschaften/',
    mannschaftenBereiche: 'mannschaftenBereiche/',
    ticker:               'ticker/',
    times:                'zeiten/',
    ts7:                  'ts7/',
};
