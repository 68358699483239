//  _        _      _         _
// | |  _  _| |_  _| |__ _  _| |__ _  _
// | |_| || | | || | '_ \ || | '_ \ || |
// |____\_,_|_|\_,_|_.__/\_,_|_.__/\_,_|
//
// Copyright © Lulububu Software GmbH - All Rights Reserved
// https://lulububu.de
//
// Unauthorized copying of this file, via any medium is strictly prohibited!
// Proprietary and confidential.

import ComponentHelper        from '../../../helper/ComponentHelper';
import PropTypes              from '../../PropTypes';
import React                  from 'react';
import styles                 from './styles.module.scss';
import { bindActionCreators } from 'redux';
import _                      from 'lodash';
import { GameActions }        from '../../../store/actions/game';
import { connect }            from 'react-redux';
import I18n                   from 'i18next';

class RacingStableDropDown extends React.Component {
    shouldComponentUpdate (nextProps, nextState) {
        return ComponentHelper.shouldComponentUpdate(
            this,
            Component,
            nextProps,
            nextState,
        );
    }

    onChange = (event) => {
        this.props.selectRacingStable({
            racingStable: event.target.value,
        });
    };

    render () {
        const sortedData = _.sortBy(this.props.racesData, 'id');

        return (
            <div className={styles.wrapper}>
                <select onChange={this.onChange}>
                    <option value={'ALL'}>
                        {I18n.t('noRacingStable')}
                    </option>
                    {sortedData.map(this.renderItem)}
                </select>
            </div>
        );
    }

    renderItem = (itemData) => {
        return (
            <option
                selected={itemData.id === this.props.racingStable}
                value={itemData.id}
            >
                {itemData.id}
            </option>
        );
    };
}

const Component = RacingStableDropDown;

Component.propTypes = {
    racesData: PropTypes.array,
};

Component.defaultProps = {
    racesData: [],
};

Component.renderAffectingProps = Object.keys(Component.defaultProps);

Component.renderAffectingStates = [];

const mapStateToProps = state => (
    {
        racingStable: state.game.racingStable,
    }
);

const mapDispatchToProps = dispatch => bindActionCreators(_.assign(
    GameActions,
), dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Component);
